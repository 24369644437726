import React from "react";
import AppPage from "../../components/templates/appPage";
import CreateMeetupButton from "../../components/createMeetup/button";
import ActivitiesList from "../../components/ActivitiesList";
const MyActivitiesPage: React.FC = () => {
  return (
    <AppPage>
      <main className="flex flex-col justify-start items-center gap-4 bg-gray-200">
        <div className="flex flex-col justify-center items-center bg-white w-full pt-6 pb-6 shadow-lg">
          <CreateMeetupButton />
        </div>
        <div className="w-full h-full bg-white">
          <div className="pt-2 pl-4 text-2xl font-title">Mes activites</div>
          <ActivitiesList />
        </div>
      </main>
    </AppPage>
  );
};

export default MyActivitiesPage;
