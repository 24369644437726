import React from "react";
import { Link } from "react-router-dom";

interface menuProps {
  active: Boolean;
}

const NavMenu: React.FC<menuProps> = (props: menuProps) => {
  // The styling make the menu appear or disapear
  const [scaling, setScaling] = React.useState("scale-0");
  const menuItemStyling =
    "h-12 mt-px mb-px bg-gray-700 text-gray-200 flex flex-row justify-center items-center transition transition-all hover:translate-x-2 " +
    scaling;

  const toggleMenu = () => {
    if (props.active) {
      // Toggle menu On
      setScaling(" scale-1");
    } else {
      // Toggle menu off
      setScaling("scale-0");
    }
  };
  React.useEffect(toggleMenu, [props.active]);

  return (
    <menu className="fixed w-48  -mt-6 sm:mt-24 ml-3 justify-around z-50 ">
      <Link to="/auth/login" className={menuItemStyling + " rounded-t-2xl"}>
        Se connecter
      </Link>
      <Link to="/" className={menuItemStyling + " rounded-b-2xl"}>
        À propos
      </Link>
    </menu>
  );
};

export default NavMenu;
