import { createContext } from "react";
import { toBool, parseUser } from "./authUtils";

const authContext = createContext({
  isAuth: toBool(localStorage.getItem("isAuth")),
  user: parseUser(localStorage.getItem("user")),
  update: (isAuth: boolean, user: object) => {},
});

export default authContext;
