import httpPost from "./httpPost";

interface createMeetupPayload {
  meetupName: string;
  difficulty: number;
  description: string;
  placeName: string;
  date: string;
  hoursStart: number;
  minutesStart: number;
  duration: string;
  latitude: number;
  longitude: number;
}

interface ApiMeetupPayload {
  name: string;
  difficulty: number;
  description: string;
  place_name: string;
  latitude: number;
  longitude: number;
  duration: string;
  start_hour: string;
}

const postCreateMeetup = async (payload: createMeetupPayload) => {
  try {
    const hoursStart =
      payload.hoursStart < 10 ? "0" + payload.hoursStart : payload.hoursStart;
    const minutesStart =
      payload.minutesStart < 10
        ? "0" + payload.minutesStart
        : payload.minutesStart;

    const dateStringISO =
      payload.date + "T" + hoursStart + ":" + minutesStart + ":00";
    const date = new Date(dateStringISO);
    const updatedPayload: ApiMeetupPayload = {
      name: payload.meetupName,
      difficulty: payload.difficulty,
      description: payload.description,
      place_name: payload.placeName,
      latitude: payload.latitude,
      longitude: payload.longitude,
      duration: payload.duration,
      start_hour: date.toJSON(),
    };
    const responseData = await httpPost("meetup/create", updatedPayload);
    if (responseData !== undefined) {
      return responseData;
    }
  } catch (err) {
    console.error(err);
  }
};

export default postCreateMeetup;
