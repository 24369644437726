import React from "react";
import AppPage from "../../components/templates/appPage";
import postCreateMeetup from "../../http/postCreateMeetup";
import { Navigate } from "react-router-dom";
import ChooseLocationMap from "../../components/createMeetup/chooseLocationMap";

const CreateMeetupPage: React.FC = () => {
  const [meetupName, updateMeetupName] = React.useState<string>("");
  const [difficulty, updateDifficulty] = React.useState<number>(8);
  const [description, updateDescription] = React.useState<string>("");
  const [placeName, updatePlaceName] = React.useState<string>("");
  const [date, updateDate] = React.useState<string>("");
  const [minutesStart, updateMinutesStart] = React.useState(30);
  const [hourStart, updateHourStart] = React.useState(8);
  const [duration, updateDuration] = React.useState<string>("");
  const [success, setSuccess] = React.useState(false);
  const [mapComponent, updateMapComponent] = React.useState(<></>);
  const [lat, setLat] = React.useState<number>(0);
  const [lng, setLng] = React.useState<number>(0);

  const validateCoordinates = (modalLng: number, modalLat: number) => {
    setLat(modalLat);
    setLng(modalLng);
    console.log(modalLat, modalLng);
    setTimeout(() => {
      updateMapComponent(<></>);
    }, 500);
  };

  const displayMap = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Displaying map");
    updateMapComponent(
      <ChooseLocationMap validateCoordinates={validateCoordinates} />
    );
  };

  const submitNewMeeetup = (e: React.FormEvent) => {
    e.preventDefault();
    postCreateMeetup({
      meetupName: meetupName,
      difficulty: difficulty,
      description: description,
      placeName: placeName,
      date: date,
      hoursStart: hourStart,
      minutesStart: minutesStart,
      duration: duration,
      latitude: lat,
      longitude: lng,
    })
      .then((response) => {
        if (response && response.success) {
          setSuccess(true);
        }
      })
      .catch((err) => console.error(err));
  };

  return success ? (
    <Navigate to="/app" />
  ) : (
    <AppPage>
      {mapComponent}
      <div className="p-4 shadow-lg bg-beige-100">
        <h1 className="font-bold text-3xl text-yellow-800 ">Créer un Meetup</h1>
        <span className="font-sm">
          Avant que de nombreux passionnés vous rejoignent, nous avons besoin de
          quelques informations supplémentaires sur votre sortie
        </span>
      </div>
      <div className="h-4 w-screen bg-gray-200"></div>
      <form onSubmit={submitNewMeeetup}>
        <div className="shadow-lg flex flex-col gap-4 p-4">
          <h2 className="font-bold text-2xl text-yellow-800">Général</h2>
          <input
            type="text"
            value={meetupName}
            onChange={(e) => updateMeetupName(e.target.value)}
            placeholder="Nom du Meetup"
            className="p-3 w-2/3 bg-gray-100 rounded-xl"
          />
          <label htmlFor="difficulty" className="font-bold">
            Difficulté
          </label>
          <input
            name="difficulty"
            value={difficulty}
            onChange={(e) => updateDifficulty(parseFloat(e.target.value))}
            type="range"
            placeholder="Nom du Meetup"
            className="p-3 w-1/2"
          />
          <textarea
            placeholder="Notes de l'organisateur"
            value={description}
            onChange={(e) => {
              updateDescription(e.target.value);
            }}
            className="p-3 w-full bg-gray-100 rounded-xl h-36"
          />
        </div>
        <div className="h-4 w-screen bg-gray-200"></div>
        <div className="shadow-lg flex flex-col gap-4 p-4">
          <h2 className="font-bold text-2xl text-yellow-800">Localisation</h2>
          <input
            type="text"
            placeholder="Nom du lieu-dit"
            value={placeName}
            onChange={(e) => {
              updatePlaceName(e.target.value);
            }}
            className="p-3 w-2/3 bg-gray-100 rounded-xl"
          />
          <button
            className="bg-beige-200 text-gray-600 font-bold font-lg w-1/2 rounded-full p-3"
            onClick={displayMap}
          >
            Pointer sur la carte
          </button>
        </div>
        <div className="h-4 w-screen bg-gray-200"></div>
        <div className="shadow-lg flex flex-col gap-4 p-4">
          <h2 className="font-bold text-2xl text-yellow-800">Horaires</h2>
          <div>
            <label htmlFor="date" className="font-bold">
              Date
            </label>
            <input
              className="ml-5"
              type="date"
              value={date}
              onChange={(e) => updateDate(e.target.value)}
              name="date"
            />
          </div>
          <div className="flex flex-row gap-1">
            <label className="font-bold translate-y-3 pr-3">
              Horaire de départ
            </label>
            <input
              type="number"
              value={hourStart}
              onChange={(e) => {
                updateHourStart(parseInt(e.target.value));
              }}
              className="bg-gray-100 rounded-xl h-12 w-12 text-gray-600 font-bold text-xl align-middle text-center"
            />
            <div className="font-bold text-2xl translate-y-1 text-gray-600">
              :
            </div>
            <input
              type="number"
              value={minutesStart}
              onChange={(e) => {
                updateMinutesStart(parseInt(e.target.value));
              }}
              className="bg-gray-100 rounded-xl h-12 w-12 text-gray-600 font-bold text-xl align-middle text-center"
            />
          </div>
          <div>
            <label htmlFor="duration" className="font-bold">
              Durée estimée
            </label>
            <input
              type="text"
              value={duration}
              onChange={(e) => updateDuration(e.target.value)}
              placeholder="3h30"
              className="bg-gray-100 rounded-xl h-12 w-1/4 ml-5 text-gray-600 font-bold text-xl align-middle text-center"
            />
          </div>
          <button
            type="submit"
            className="p-3 w-1/2 bg-yellow-800 rounded-xl self-center font-bold text-white text-lg "
          >
            Valider
          </button>
        </div>
      </form>
    </AppPage>
  );
};

export default CreateMeetupPage;
