import { authContextType } from "./authTypes";
import { deleteUser, getAuth } from "firebase/auth";

const logoutFunc = (authContext: authContextType): void => {
  if (authContext.isAuth) {
    const firebaseAuth = getAuth();
    firebaseAuth.signOut();
    authContext.update(false, {});
    window.location.href = "/";
  } else {
    window.location.href = "/auth/login";
  }
};

export default logoutFunc;
