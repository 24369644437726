import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import loginFunc from "../../authLogic/loginFunc";
import authContext from "../../authLogic/authContext";
import { Navigate } from "react-router";

interface formData {
  email: string;
  password: string;
}

const checkLoginFormData = (formData: formData) => {
  if (
    !String(formData.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return { status: false, message: "Please enter a valid email" };
  }
  if (formData.password === "") {
    return { status: false, message: "Please enter a password" };
  }
  return { status: true, message: "" };
};

const Login: React.FC = () => {
  const [formData, updateFormData] = React.useState({
    email: "",
    password: "",
  });
  const auth = React.useContext(authContext);
  const [message, updateMessage] = React.useState(<></>);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    const isDataValid = checkLoginFormData(formData);
    if (!isDataValid.status) {
      updateMessage(<div className="text-red-500">{isDataValid.message}</div>);
    } else {
      updateMessage(<></>);
      try {
        const isLoginSuccess = await loginFunc(formData, auth);
        console.log(isLoginSuccess);
        if (!isLoginSuccess.success) {
          updateMessage(
            <div className="text-red-500">{isLoginSuccess.message}</div>
          );
        } else {
          window.location.href = "/app";
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return auth.isAuth ? (
    <Navigate to="/app" />
  ) : (
    <>
      <Helmet>
        <title>Riders' Coffee | Rejoins-nous !</title>
        <meta
          name="description"
          content="Bienvenue sur Riders' Coffee, le site qui te permet de trouver des sorties de groupe, pour découvrir en vélo les plus belles régions de France"
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <main className="flex background-image-1 bg-fixed bg-clip-border bg-cover justify-center items-center w-view h-screen overflow-hidden">
        <div className="container mx-auto  w-6/12 h-96 mb-12">
          <h1 className="text-center font-bold text-5xl mb-10">
            Bienvenue sur
            <span className="text-yellow-800"> Riders' Coffee</span>
          </h1>
          <form
            className="transition all duration-1000 container bg-gray-100 rounded-3xl h-full flex flex-col gap-9 items-center justify-center shadow-xl"
            onSubmit={handleLogin}
          >
            <input
              type="text"
              name="id"
              placeholder="Identifiant"
              value={formData.email}
              onChange={(e) => {
                updateFormData({
                  email: e.target.value,
                  password: formData.password,
                });
              }}
              className="bg-white w-8/12 h-12 rounded-xl p-5"
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={(e) => {
                updateFormData({
                  email: formData.email,
                  password: e.target.value,
                });
              }}
              className="bg-white w-8/12 h-12 rounded-xl p-5"
            />
            <button
              type="submit"
              className=" bg-yellow-900 transition-all duration-1000 font-bold text-white bg-blue-700 hover:bg-blue-500 w-4/12 h-14 rounded-2xl"
            >
              Login
            </button>
            <div>
              Tu n'as pas encore de compte ?
              <Link to="/auth/sign-up">
                <span className="text-blue-700 font-bold cursor-pointer">
                  Rejoins nous !
                </span>
              </Link>
            </div>
          </form>
          <div className="messageContainer">{message}</div>
        </div>
      </main>
    </>
  );
};

export default Login;
