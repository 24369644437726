export function toBool(string: string | null): boolean {
  if (string === "true") {
    return true;
  } else {
    return false;
  }
}

export function parseUser(userString: string | null): object {
  if (userString === null) {
    return {};
  } else {
    return JSON.parse(userString);
  }
}
