import React from "react";
import AppPage from "../../components/templates/appPage";
import MapContainer from "../../components/activitiesMap/mapContainerJS.jsx";
import SearchBar from "../../components/activitiesMap/searchBar";

const WelcomePage: React.FC = () => {
  return (
    <AppPage>
      <SearchBar />
      <MapContainer />
    </AppPage>
  );
};

export default WelcomePage;
