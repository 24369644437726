import React from "react";
import { FaMapSigns } from "react-icons/fa";
import { FaRunning } from "react-icons/fa";
import { BsPersonLinesFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const NavContainer: React.FC = () => {
  return (
    <nav className="fixed bottom-0 z-50 w-screen h-20 bg-white border-t-[1px] border-gray-700 grid grid-cols-3 place-items-center">
      <Link to="/app/my-activities">
        <FaRunning className="text-gray-800 w-10 h-10 mx-auto" />
        <div className="text-sm">Mes activités</div>
      </Link>
      <Link to="/app/">
        <FaMapSigns className="text-gray-800 w-10 h-10 mx-auto" />
        <div className="text-sm">Carte</div>
      </Link>
      <Link to="/app/profile">
        <BsPersonLinesFill className="text-gray-800 w-10 h-10 mx-auto" />
        <div className="text-sm">Profil</div>
      </Link>
    </nav>
  );
};

export default NavContainer;
