import React from "react";
import AppPage from "../../components/templates/appPage";
import authContext from "../../authLogic/authContext";
import LogoutButton from "../../components/logoutButton";
import TokenLogger from "../../components/logToken";

const ProfilePage: React.FC = () => {
  return (
    <AppPage>
      <TokenLogger />
      <LogoutButton />
    </AppPage>
  );
};

export default ProfilePage;
