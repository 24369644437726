import React from "react";

interface DifficultyIconProps {
  children?: React.ReactNode;
  difficulty: number;
}

const DifficultyIcon: React.FC<DifficultyIconProps> = ({
  difficulty,
  children,
}) => {
  if (difficulty <= 25) {
    return (
      <div className="w-14 h-4 text-sm text-green-900 rounded-full bg-green-600 flex justify-center items-center">
        Facile
      </div>
    );
  } else if (difficulty <= 50) {
    return (
      <div className="w-14 h-4 text-sm text-blue-900 rounded-full bg-blue-600 flex justify-center items-center">
        Moyen
      </div>
    );
  } else if (difficulty <= 75) {
    return (
      <div className="w-14 h-4 text-sm text-red-900 rounded-full bg-red-600 flex justify-center items-center">
        Dur
      </div>
    );
  } else {
    return (
      <div className="w-14 h-4 text-sm text-white rounded-full bg-gray-900 flex justify-center items-center">
        Expert
      </div>
    );
  }
};

export default DifficultyIcon;
