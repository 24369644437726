import httpGet from "./httpGet";

const getMyActivities = async () => {
  try {
    const limit = 20;
    console.log("Fetching my activities ...");
    const data = await httpGet("meetup/list/owned?limit=" + limit.toString());
    if (data && data.success && data.payload) {
      return data;
    } else {
      throw new Error("Error while querying the API");
    }
  } catch (err) {
    throw Error();
  }
};

export default getMyActivities;
