import React from "react";
import { BiSearchAlt } from "react-icons/bi";

const SearchBar: React.FC = () => {
  return (
    <div className="fixed z-10 block bg-gray-100 w-5/6 h-12 rounded-full opacity-90 top-8 left-[8.333%] focus:border-yellow-700">
      <input
        placeholder="Rechercher"
        className="bg-transparent w-full h-12 rounded-full placeholder:pl-6"
      />
      <BiSearchAlt className="fixed block top-12 scale-124 text-yellow-800 right-[14%]" />
    </div>
  );
};

export default SearchBar;
