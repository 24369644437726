import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import getMeetups from "./fetchMeetupsWithinMap";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapContainer = () => {
  const markerContainer = useRef(null);
  const mapContainer = useRef(null);
  const marker = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(2.13);
  const [lat, setLat] = useState(48.7);
  const [zoom, setZoom] = useState(9);
  const [ArrayOfMeetups, setArrayOfMeetups] = useState([]);
  const [refreshIsClicked, setrefreshIsClicked] = useState(true);

  useEffect(() => {
    if (!map.current) return;
    getMeetups(lat - zoom, lat + zoom, lng - zoom, lng + zoom)
      .then((response) => {
        if (response && response.success) {
          setArrayOfMeetups(response.payload);
        } else {
          throw Error("Couldn't fetch data from API");
        }
      })
      .catch((err) => console.error(err));
  }, [refreshIsClicked]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
  });

  useEffect(() => {
    //Remove the old markers
    if (marker.current) {
      marker.current.remove();
    }

    ArrayOfMeetups.forEach((item) => {
      marker.current = new mapboxgl.Marker({
        container: mapContainer.currrent,
        scale: 1,
        color: "#FFC78C",
      })
        .setLngLat([item.longitude, item.latitude])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(`
              <div class="rounded-xl ">
                <h3 class="font-title text-xl text-center">${item.name}</h3>
                <p class="relative w-[90%] mt-2 left-[5%] h-32 p-3 bg-gray-100 border-[1px] border-gray-600 rounded-xl">${item.description}</p>
                <a href="/app/meetups/detail/${item.id}" class="button-detail relative rounded-full w-1/2 mt-2 left-1/4 bg-green-200 h-8 p-4 flex items-center justify-center">Détails</a>
              </div>
              `)
        )
        .addTo(map.current);
      const detailsButton = document.querySelector(".button-detail");
      console.log(detailsButton);
      // detailsButton.addEventListener("click", () => {
      //   window.location.href(`/app/activity-detail/${item.id}`);
      // });
    });
  }, [ArrayOfMeetups]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
    });
  });

  return (
    <>
      <div ref={mapContainer} className="map-container w-screen h-screen" />
      <div ref={markerContainer} className="mapboxgl-marker"></div>
      <button
        onClick={() => setrefreshIsClicked(!refreshIsClicked)}
        className="fixed bottom-24 rounded-2xl p-2 shadow-lg w-2/3 left-[16.66%] hover:bg-gray-400  h-12 z-20 bg-gray-200 text-gray-500"
      >
        Rechercher dans cette zone
      </button>
    </>
  );
};

export default MapContainer;
