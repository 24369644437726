import React from "react";
import PrivateComponent from "./privatePage";
import NavContainer from "../../components/navbarApp/navContainer";

interface OnlyChildrenProps {
  children?: React.ReactNode;
}

const AppPage: React.FC = (props: OnlyChildrenProps) => {
  return (
    <PrivateComponent>
      {props.children}
      <div className="w-screen h-20"></div>
      <NavContainer />
    </PrivateComponent>
  );
};

export default AppPage;
