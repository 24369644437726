import React from "react";
import logoutFunc from "../authLogic/logoutFunc";
import AuthContext from "../authLogic/authContext";

const LogoutButton: React.FC = () => {
  const auth = React.useContext(AuthContext);
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        logoutFunc(auth);
      }}
    >
      Se déconnecter
    </button>
  );
};

export default LogoutButton;
