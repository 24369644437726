import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const ChooseLocationMap = ({ validateCoordinates }) => {
  const mapContainer = useRef(null);
  const markerContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);
  const [mouseCoordinates, setMouseCoordinates] = useState();
  const [validateButton, setValidateButton] = useState(<></>);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    map.current.on("click", (e) => {
      setMouseCoordinates(e.lngLat);
    });
  });

  // Intialize the marker

  const createMarker = () => {
    if (!marker.current) {
      marker.current = new mapboxgl.Marker({
        container: mapContainer.currrent,
        scale: 1,
        color: "#FA1901",
        draggable: true,
      })
        .setLngLat([mouseCoordinates.lng, mouseCoordinates.lat])
        .addTo(map.current);

      setValidateButton(
        <button
          onClick={() => {
            const lngLat = marker.current.getLngLat();
            validateCoordinates(lngLat.lng, lngLat.lat);
          }}
          className="absolute w-4/5 h-14 rounded-full bg-green-400 bottom-6 left-[10%] shadow-xl"
        >
          Valider le point de départ
        </button>
      );
    }
  };
  return (
    <div className="fixed z-10 bg-white h-3/4 w-5/6 shadow-lg rounded-2xl top-14 left-8 overflow-hidden">
      <div className="w-full h-16 bg-gray-100 p-2 text-center">
        Cliquer pour sélectionner le point de départ
      </div>
      <div ref={mapContainer} onClick={createMarker} className="h-full "></div>
      <div ref={markerContainer} className="mapboxgl-marker"></div>
      {validateButton}
    </div>
  );
};

export default ChooseLocationMap;
