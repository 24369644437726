import React from "react";
import { Routes, Route } from "react-router-dom";

import NoMatch from "../pages/404";
import MapPage from "../pages/app/map";
import ProfilePage from "../pages/app/profile";
import MyActivitiesPage from "../pages/app/myActivities";
import CreateMeetupPage from "../pages/app/createMeetup";
import MeetupDetailPage from "../pages/app/meetupDetail";

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<MapPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/my-activities" element={<MyActivitiesPage />} />
      <Route path="/meetups/create" element={<CreateMeetupPage />} />
      <Route path="/meetups/detail/:id" element={<MeetupDetailPage />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default AppRouter;
