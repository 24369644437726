import httpGet from "../../http/httpGet";
import { getAPIResponse } from "../../http/responseTypes";

const fetchMeetupsWithinMap = async (
  minLat: number,
  maxLat: number,
  minLng: number,
  maxLng: number
) => {
  try {
    const response: getAPIResponse = await httpGet(
      `meetup/within_rectangle?min_lat=${minLat}&max_lat=${maxLat}&min_lng=${minLng}&max_lng=${maxLng}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export default fetchMeetupsWithinMap;
