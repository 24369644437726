import React from "react";
import { Helmet } from "react-helmet";
import backgroundImage from "../assets/img/background-1.jpg";
import NavContainer from "../components/navbarAbout/navContainer";
import MobileAppDemo from "../assets/icons/mobile_app_demo.png";
import LogoRCWhite from "../assets/icons/logo-rc-white.svg";
import FacebookIcon from "../assets/icons/facebook.svg";
import LinkedInIcon from "../assets/icons/linkedin.svg";
import InstagramIcon from "../assets/icons/instagram.svg";
import EmailIcon from "../assets/icons/email.svg";
import ProfilePictureDemo from "../assets/img/profile-picture-demo.jpg";
import WaveRoad from "../assets/icons/wave-road.svg";

const aboutPage = () => {
  return (
    <>
      <Helmet>
        <title>Riders' Coffee | Welcome</title>
        <meta
          name="description"
          content="Bienvenue sur Riders' Coffee. Retrouvez ici tous les passionnés de sport de plein air !"
        />
      </Helmet>
      <NavContainer />
      <main>
        <img
          src={backgroundImage}
          alt=" Background image"
          className="hidden lg:inline-block "
        />
        <img
          src={WaveRoad}
          alt="A wave that's a road"
          className="hidden md:absolute md:block mt-8 mb-8 md:mb-20 md:mt-20"
        />
        <div className="mt-32 md:mt-24 ml-8 sm:ml-20">
          <div className="font-title text-5xl ">
            <span>Trouvez des </span>
            <br />
            <span className="text-yellow-900">partenaires </span>
            <span>d'activite</span>
          </div>
          <div className="mr-8 sm:mr-0 sm:w-[50vw] mt-8 sm:mt-4 text-sm mb-4 ">
            Grâce à riders-coffee.com, plus besoin de passer des heures devant
            une carte ou dans les files d’attente, découvrez ou re-découvrez des
            activités de plein air grâce à des passionnés
          </div>
        </div>
        <div className="relative md:mb-24 bg-beige-100 w-[80vw] sm:w-[40vw] mt-12 -right-10 h-28 mb-16 rounded-3xl shadow-lg sm:left-1/2 flex flex-row items-center gap-8 pl-8 pr-8 sm:p-5}">
          <div className="rounded-full h-16 w-16 overflow-hidden">
            <img src={ProfilePictureDemo} alt="Profile" />
          </div>
          <div className="flex flex-col justify-around items-start">
            <div className="font-bold text-xl text-yellow-900">
              Sortie à vélo de Natalie
            </div>
            <div>Difficulté : 3/5 | Longueur : 35km </div>
          </div>
        </div>

        <div className="flex flex-col pb-8 pt-16 pl-20 pr-20 sm:p-0 sm:flex-row justify-around items-center relative sm:pl-32 sm:pr-32 bg-beige-200 overflow-hidden w-screen sm:h-[35rem] after:absolute after:z-10 after:content-[''] after:rounded-full after:bg-beige-100 after:w-[70rem] after:h-[70rem] after:block after:-top-56 after:-left-1/3">
          <img
            src={MobileAppDemo}
            className="max-h-96 sm:h-3/5 z-20"
            alt="Mobile app preview "
          />
          <div className="z-20 mt-8 font-bold sm:font-normal text-center sm:text-left gap-6 sm:gap-0 text-md sm:text-xl h-1/3 flex flex-col justify-around">
            <div>Trouvez des MeetUps sur notre carte interactive</div>
            <div>Discutez avec vos partenaires avant et après le MeetUp</div>
            <div>Créez vos MeetUps et invitez vos amis !</div>
          </div>
        </div>
      </main>
      <footer className="w-screen md:h-[35rem] pt-12 md:pt-24 pb-12 md:pb-24 md:pl-12 md:pr-12 bg-gray-700 flex flex-col md:flex-row justify-between items-center">
        <div className="md:border-r-4 border-gray-400">
          <img
            src={LogoRCWhite}
            className="scale-75"
            alt="Riders' Coffee logo white"
          />
        </div>
        <div className="flex flex-row flex-wrap gap-8 md:gap-0 md:flex-col text-gray-400 justify-around text-xl pl-4 pr-4 md:w-1/3">
          <div className="md:m-6">À propos</div>
          <div className="md:m-6">Nous contacter</div>
          <div className="md:m-6">Mentions légales</div>
          <div className="md:m-6">Plan du site </div>
        </div>
        <div className="flex flex-row mt-8 sm:mt0 md:flex-col justify-start">
          <a href="#https://facebook.com/riders-coffee" className="scale-50">
            <img src={FacebookIcon} alt="Facebook Icon" />
          </a>
          <a href="#" className="scale-50">
            <img src={InstagramIcon} alt="Instagram Icon" />
          </a>
          <a href="#" className="scale-50">
            <img src={LinkedInIcon} alt="Instagram Icon" />
          </a>
          <a href="#" className="scale-50">
            <img src={EmailIcon} alt="Email icon" />
          </a>
        </div>
      </footer>
    </>
  );
};

export default aboutPage;
