import React, { useEffect } from "react";
import { getAuth, getIdToken } from "firebase/auth";

const TokenLogger: React.FC = () => {
  const [idToken, updateToken] = React.useState<string>("");

  const tokenGetterFunc = async () => {
    try {
      const authFirebase = getAuth();
      const user = authFirebase.currentUser;
      if (user === null) {
        throw Error("User isn't authenticated");
      }
      const intermediateToken = await getIdToken(user, true);
      updateToken(intermediateToken);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    tokenGetterFunc();
  });
  return (
    <div>
      Your token is :
      <div className="w-5/6 bg-gray-100 rounded-2xl p-3 overflow-x-scroll">
        {" "}
        {idToken}
      </div>
    </div>
  );
};

export default TokenLogger;
