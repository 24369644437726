import React from "react";
import { Link } from "react-router-dom";

const CreateMeetupButton: React.FC = () => {
  return (
    <Link
      to="/app/meetups/create"
      className="bg-yellow-800 w-4/5 h-16 text-white text-xl font-title flex items-center justify-center rounded-3xl"
    >
      Creer un Meetup !
    </Link>
  );
};

export default CreateMeetupButton;
