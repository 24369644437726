import React from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { loginProps, authContextType } from "./authTypes";

const loginFunc = async (
  loginProps: loginProps,
  authContext: authContextType
): Promise<{ success: boolean; message: string }> => {
  const authFirebase = getAuth();
  try {
    if (authContext.isAuth) {
      return { success: false, message: "You are already authenticated" };
    }
    const userCredentials = await signInWithEmailAndPassword(
      authFirebase,
      loginProps.email,
      loginProps.password
    );
    authContext.update(true, userCredentials);
    return { success: true, message: "Successfully authenticated" };
  } catch (err) {
    console.log(err);
    return { success: false, message: "Couldn't identify user" };
  }
};

export default loginFunc;
