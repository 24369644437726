// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB4b3AQ1ygDPQQLsFG_Pk3qB9CAuSGLBRk",
  authDomain: "riders-coffee.firebaseapp.com",
  projectId: "riders-coffee",
  storageBucket: "riders-coffee.appspot.com",
  messagingSenderId: "40379342698",
  appId: "1:40379342698:web:1893f36949cca4327a0f13",
  measurementId: "G-HBWZ5L47YC",
};

// Initialize Firebase

const firebaseInit = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
};

export default firebaseInit;
