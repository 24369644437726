import React from "react";
import AppPage from "../../components/templates/appPage";
import { useParams } from "react-router-dom";

const MeetupDetailPage: React.FC = () => {
  const { id } = useParams();
  return <AppPage>See meetup details here</AppPage>;
};

export default MeetupDetailPage;
