import get from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { getAPIResponse } from "./responseTypes";

const useApiGet: (url: string) => Promise<getAPIResponse> = async (url) => {
  try {
    const authFirebase = await getAuth();
    const user = authFirebase.currentUser;
    if (user === null) {
      throw Error("User isn't authenticated");
    }
    const idToken = await getIdToken(user, true);
    const fullURL = process.env.REACT_APP_API_URL + url;
    const response = await get(fullURL, {
      headers: { Authorization: "Bearer " + idToken },
    });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export default useApiGet;
