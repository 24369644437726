import React from "react";
import AuthContext from "./authContext";
import { toBool, parseUser } from "./authUtils";

interface childrenOnly {
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<childrenOnly> = (props) => {
  const [authState, updateAuthState] = React.useState({
    isAuth: toBool(localStorage.getItem("isAuth")),
    user: parseUser(localStorage.getItem("user")),
  });

  const updateContext = (isAuth: boolean, user: object) => {
    updateAuthState({ isAuth: isAuth, user: user });
    localStorage.setItem("isAuth", isAuth.toString());
    localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <AuthContext.Provider
      value={{ ...authState, ...{ update: updateContext } }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
