import React from "react";
import { useQuery } from "react-query";
import fetchMyActivities from "../http/getMyActivities";
import Squeleton from "../components/loadingSqueleton/activitySqueleton";
import { GiRun } from "react-icons/gi";
import DifficultyIcon from "../components/difficultyIcon";

interface ActivitiesSummary {
  name: string;
  difficulty: number;
  place_name: number;
}

const ActivitiesList: React.FC = () => {
  const { isLoading, error, data } = useQuery(
    "getMyActivities",
    fetchMyActivities
  );

  if (isLoading) {
    return (
      <div className="flex flex-col gap-3 items-center pt-4">
        <Squeleton />
        <Squeleton />
        <Squeleton />
        <Squeleton />
        <Squeleton />
      </div>
    );
  } else if (error) {
    console.log(error);
    return <div>There was an error in the fetching process</div>;
  } else if (data && data.success) {
    const payloadArray = data.payload;
    const content = payloadArray.map((item: ActivitiesSummary) => {
      return (
        <div className="bg-beige-100 w-5/6 h-20 rounded-xl grid grid-cols-4 grid-rows-2 shadow-md pt-3 pb-2">
          <div className="col-start-1 col-end-1 row-span-2 flex items-center justify-center">
            <GiRun className="scale-[2.5] text-yellow-700" />
          </div>
          <div className="col-start-2 col-end-5 row-span-1 text-lg font-bold">
            {item.name}
          </div>
          <div className="col-start-2 col-end-2 text-sm">
            <DifficultyIcon difficulty={item.difficulty} />
          </div>
          <div className="col-start-3 col-end-5 text-sm">
            RDV : {item.place_name}
          </div>
        </div>
      );
    });
    return (
      <div className="flex flex-col gap-4 items-center pt-4">{content}</div>
    );
  }
  return <div>Unexpected error</div>;
};
export default ActivitiesList;
