import React from "react";
import { Navigate } from "react-router";
import authContext from "../../authLogic/authContext";

interface pageProps {
  children?: React.ReactNode;
}

const PrivateComponent = (props: pageProps) => {
  const auth = React.useContext(authContext);
  const content = auth.isAuth ? (
    <>{props.children}</>
  ) : (
    <Navigate to="/auth/login" />
  );
  return content;
};

export default PrivateComponent;
