import axios from "axios";
import { AxiosResponse } from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { defaultAPIResponse } from "./responseTypes";

const useApiPost: (
  url: string,
  payload: object
) => Promise<defaultAPIResponse | undefined> = async (url, payload) => {
  try {
    const authFirebase = await getAuth();
    const user = authFirebase.currentUser;
    if (user === null) {
      throw Error("User isn't authenticated");
    }
    const idToken = await getIdToken(user, true);

    const fullURL = process.env.REACT_APP_API_URL + url;
    const response: AxiosResponse = await axios.post(fullURL, payload, {
      headers: { Authorization: "Bearer " + idToken },
    });
    if (response.data && response.data.success && response.data.msg) {
      return response.data;
    } else {
      return {
        success: false,
        msg: "Response type doesn't match API standards",
      };
    }
  } catch (err) {
    console.error(err);
  }
};

export default useApiPost;
