import React from "react";

const ActivitySqueleton: React.FC = () => {
  return (
    <div className="bg-gray-300 w-5/6 h-20 rounded-xl grid grid-cols-2 grid-rows-2 gap-4 p-4 shadow-md animate-pulse">
      <div className="bg-gray-400 rounded-xl col-span-2 row-span-1"></div>
      <div className="bg-gray-400 rounded-xl col-start-1 col-end-1 row-start-2"></div>
      <div className="bg-gray-400 rounded-xl col-start-2 col-span-1 row-start-2"></div>
    </div>
  );
};

export default ActivitySqueleton;
